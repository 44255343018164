.lk-user-block {
  position: absolute;
  top: 5px;
  right: 10px;
}

.lk-region {
  float: left;
  margin-bottom: 50px;
}

.lk-add-member {
  float: right;
}




.lk-members-tabs {

  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;


  a {
    display: inline-block;
    padding: 10px;
    background: #fff;
    margin-right: 5px;
    border-bottom: 4px solid #fff;
    color: inherit;
    text-decoration: none;

    &.active {
      border-color: #47a3da;
    }
  }

}

.lk-members-table {

  width: 100%;

  td, th {
    padding: 5px 10px;
    border-bottom: 1px solid #e8e8e8;
  }

  th {
    background: #e8e8e8;
  }

  td {
    background: #fff;
    text-align: center;

    &:nth-child(2) {text-align: left}
  }

  .js-register {

    display: inline-block;
    color: #fff;
    background: #47a3da;
    padding: 5px;
    width: 27px;

    &.-disable {
      opacity: .3;
      cursor: default;
    }

  }

  .js-unregister {

    display: inline-block;
    color: #fff;
    background: darkred;
    padding: 5px;
    width: 27px;

  }

}

.view-members {



  .views-field-field-ref-events {
    text-align: center;

    .reg-event-item {
      margin-bottom: 5px;
    }

    .btn-delete {
      color: red;
    }
  }

}


.node-member2 {

  position: relative;
  padding-left: 230px;
  min-height: 300px;



  .content {

    display: table;

    .field {
      display: table-row;

      >div {display: table-cell;padding: 5px;border-bottom: 1px solid #e8e8e8}
    }

  }

  .field-name-field-image {
    position: absolute;
    top: 0;
    left: 0;
  }

}

.lk-tabs {
  margin-bottom: 30px;

  ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
    font-size: 18px;
    border-bottom: 2px solid lightgrey;

    li {
      display: inline-block;
      padding: 0 !important;
      margin: 0 !important;

      a {
        display: block;
        padding: 10px 20px;

      }

      &.-active {

        border-top: 1px solid lightgrey;

        a {
          background: #eeeeee;
          color: inherit;

        }

      }
    }
  }

}


.lk-events-layout {
  display: flex;
  justify-content: space-between;

  >div {
    width: 50%;
    flex: 0 0 auto;
  }

  .col-1 {
    border-right: 1px solid lightgrey;
    padding-right: 10px;
  }

  .col-2 {
    padding-left: 10px;
  }


  .col-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

}

.lk-event-select {

  text-align: right;
  margin-bottom: 15px;

  label {
    display: inline-block;
    vertical-align: middle;
  }

  select {
    width: 30%;
    height: 30px;
    font-size: 15px;
    vertical-align: middle;
  }


}

.lk-dialog {
  background: #fff;
  margin: 0 auto;
  width: 450px;
  position: relative;
  padding: 15px 20px 20px 20px;

  .dialog-header {
    border-bottom: 1px solid lightgrey;
    font-weight: bold;
    margin: 0 -20px 20px -20px;
    padding: 0 20px 10px 20px;
  }

  .dialog-body {
    margin-bottom: 20px;

    p {margin: 0 0 1em 0}

    select {
      padding: 5px 10px;
    }

  }

  .dialog-actions {

    text-align: center;

    button {
      margin: 0 10px;
      background: none;
      padding: 5px 10px;
      cursor: pointer;
      border: 1px solid #47a3da;
      background: #47a3da;
      color: #fff;

      &.btn-cancel {
        background: none;
        color: #47a3da;
      }
    }

  }

}

.lk-back-link {
  text-align: right;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid lightgrey;
}


.page-node-revisions {

  table.diff {
    margin: 20px 0;

    .diff-links {
      ul {display: none}
    }

  }

  form.confirmation {
    text-align: center;
    margin: 50px 0;
  }

  #content .tabs.secondary li {margin-left: 0}


}

