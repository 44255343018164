@import "inc/variables";

#autocomplete {background: #fff;}

.btn, .form-submit {

  background: #eeeeee;
  border: 1px solid silver;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    background: #fff;
  }

}

.form-actions {
  text-align: center;
}

.form-submit {
  margin: 0 10px 0 0;
}


